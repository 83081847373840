const { paylogic, mailInfo, linktree} = require('../../../links');
module.exports = {
    talk: {
        author: 'Dirk de Wachter',
    },
    top: {
        pre_title: 'PAUSE nodigt u uit',
        title: 'DIRK DE <strong>WACHTER</strong>',
        subtitle:
            '<strong>De kracht van troost in tijden van tegenslag</strong>',
    },
    generic: {
        date_days: 'd',
        date_hours: 'u',
        date_minutes: 'm',
        date_seconds: 's',
        at: 'om',
        read_more: 'Lees meer',
        faq: 'FAQ',
        jobs: 'Jobs',
        contact: 'Contact',
        about_talk: 'Over de talk',
        past_talks: 'Vorige talks',
    },
    about_talk_component: {
        title: 'Over de talk',
        blocks: [
            'Een nieuw hoofdstuk: PAUSE presenteert samen met <strong>Prof. Dirk De Wachter</strong> een vervolgvoorstelling. Na de overweldigende impact van de eerste talk, die eigenlijk een klassieker geworden is, beantwoorden we de vele verzoeken met deze gloednieuwe, inspirerende voorstelling. Het onderwerp van deze nieuwe voorstelling: “De kracht van troost in tijden van tegenslag”. Hoe gaan we om met tegenslagen? Met verdriet en pijn? Is het wel een goed idee om iemand met verdriet te overspoelen met goedbedoelde adviezen?',
        ],
    },
    tickets: {
        no_ticket_yet: 'Nog geen ticket?',
        no_ticket_yet_for: 'NOG GEEN TICKET VOOR DE TALK VAN DIRK DE WACHTER?',
    },
    countdown: {
        premieres_on: 'Uitzending op',
        in: 'over',
    },
    buy_ticket: 'TICKETS BESCHIKBAAR',
    already_ticket: 'Heb je al een Ticket?',
    enter_pause_universe: 'Enter het pause universum',
    available_soon: 'Beschikbaar bij de uitzending',
    watch_trailer: 'Bekijk de trailer',
    more_info: 'Meer info',
    block1: {
        title: 'Over de talk',
        blocks: [
            'De bijzondere PAUSE talks van Dirk De Wachter zijn intussen een klassieker geworden tijdens de koude maar fijne eindejaarsperiode. Dirk De Wachter’s boodschap raakt de juiste snaar, en wordt gebracht met zijn opvallende mix van veel charisma en nederigheid.',
            'Het onderwerp van deze voorstelling: “Troost”. Hoe gaan we om met tegenslagen? Met verdriet en pijn? Is het wel een goed idee om iemand met verdriet te overspoelen met goedbedoelde adviezen?',
            'Na de enorm positieve reacties van vorig jaar, beantwoorden we de vele verzoeken met deze heruitzending. Prof. Dirk De Wachter verweeft in deze talk zijn persoonlijke verhaal, waardoor het thema een diepere lading krijgt.',
            'De online voorstelling is opgenomen tegen de achtergrond van een adembenemend natuurlandschap, waar de avondzon met haar warme gloed zorgt voor een bijzonder moment.',
            'De heruitzending vindt plaats op zondag 29 december 2024 en kan in de daaropvolgende dagen herbekeken worden. Ze wordt uitgezonden via het online PAUSE platform.',
            'Met jouw ticket, steun je rechtstreeks het goede doel. Een deel van de opbrengsten gaat naar de vzw Te Gek!?. Zij wil het taboe rond geestelijke gezondheid verder doorbreken (https://www.tegek.be/).',
        ],
    },
    block2: {
        title: 'Goed doel',
        blocks: [
            'Met jouw ticket, steun je rechtstreeks het goede doel. Wij stellen de ambitie om met de ticket opbrengsten 5.000 euro te kunnen doneren aan de vzw Te Gek!?. Kunnen we dit halen met jouw steun?',
            'De <strong>vzw Te Gek!?</strong> wil het taboe rond geestelijke gezondheid verder doorbreken (<anchor href="https://www.tegek.be/"><strong>https://www.tegek.be/</strong></a>).',
        ],
    },
    practical_info: {
        title: 'Praktische info',
        blocks: [
            `Ticketverkoop start op donderdag 7 november 2024 om 10:00. Op de <anchor href="${paylogic}">ticket pagina</anchor> vind je alle informatie.`,
            'Dit is een online talk, waarvan de première plaatsvindt op zondag 29 december 2024. Kun je er op die dag niet bij zijn? Herbekijken kan in de daaropvolgende dagen.',
            'Samen met jouw ticket, zul je een geheime code per mail ontvangen. Deze code zal jou toegang geven tot het  PAUSE platform.',
            'De voorstelling is in het Nederlands en zal ondertiteld worden in het Nederlands.',
            'Vragen? Bekijk onze FAQ of contacteer ons.',
        ],
    },
    quote: "Ik vind het heel bijzonder om hier te zijn. Schoonheid doet een mens leven. Ik had niet zo'n goede dag gisteren. Ik heb wel eens wat last van van alles en dit soort toestanden kan mij persoonlijk bijzonder troosten. Over dat laatste, troost, wil ik het tijdens mijn voorstelling hebben. Ik heb daarbij zeker geen goeroe-achtige ambities, maar een gewone mens die zijn gedachten probeert te ordenen en mee te delen.",
    quote_author: 'Dirk de Wachter',
    previous_editions: {
        title: 'Vorige edities',
        editions: [
            {
                title: 'Dirk De Wachter',
                year: '',
                blocks: [
                    'De eerste voorstellingen van Dirk De Wachter werden een klassieker tijdens de koude maar knusse eindejaarsperiode. Zijn boodschap raakte de juiste snaar, en werd gebracht met de opvallende mix van veel charisma en nederigheid. De talk bracht heel veel mensen samen, van jong tot oud.',
                    'Dirk De Wachter gaf een bijzondere PAUSE talk over zijn inzichten: “Moet het leven vooral leuk zijn?” We leven in tijden waarin de “nood om succesvol te zijn” de norm is geworden en waarin we onze moeilijkheden liever verzwijgen. Dirk De Wachter zocht naar hoe we kunnen streven naar een betekenisvol leven en bouwde op zijn ervaring van meer dan 35 jaar.',
                    'PAUSE steunde de officiële <strong>Stephen Hawking Foundation</strong> en verwelkomde Prof. Thomas Hertog - die uitzonderlijk instemde om deze talk te geven - in een uniek cinematografisch decor. Mensen uit meer dan 20 landen hebben de talk bijgewoond.',
                ],
                video: {
                    file: 'f7becf07-e4a2-489b-aa66-691032ad6aae',
                },
                quote: {
                    text: 'Deze PAUSE talk was volledig buiten mijn comfortzone maar het was prachtig. Ik vind het geweldig dat ook de nieuwe generatie massaal heeft gekeken. Dat zijn de mensen die de wereld van morgen moeten maken.',
                    author: 'Psychiater Dirk De Wachter',
                    avatar: 'avatar-dirk-de-wachter.webp',
                },
            },
            {
                title: 'Thomas Hertog',
                year: 2021,
                blocks: [
                    'Wat is tijd? We denken er nooit zo over na, maar tijd zou wel één van de grootste mysteries in ons universum kunnen zijn.',
                    "Inzicht werd verkregen in de verbluffende ideeën van <strong>Stephen Hawking</strong> die science-fiction zelfs overtreffen, in deze uitzonderlijke en eenmalige talk. PAUSE nodigde Prof. Thomas Hertog uit, Stephen Hawking's goede vriend, collega en geniale theoretisch fysicus. Vlak voor Stephen's overlijden, werkten ze samen aan één laatste revolutionaire paper.",
                    'PAUSE steunde de officiële <strong>Stephen Hawking Foundation</strong> en verwelkomde Prof. Thomas Hertog - die uitzonderlijk instemde om deze talk te geven - in een uniek cinematografisch decor. Mensen uit meer dan 20 landen hebben de talk bijgewoond.',
                ],
                video: {
                    file: '51c2db7f-cd60-41f9-8218-31a85764bd38',
                },
                quote: {
                    text: 'De meeste mensen denken dat wetenschap te moeilijk of te ingewikkeld is om te begrijpen. Ik denk niet dat dat het geval is.',
                    author: 'Stephen Hawking',
                    avatar: 'avatar-stephen-hawking.webp',
                },
            },
            {
                title: 'Wim Hof',
                year: 2022,
                blocks: [
                    'Wim Hof ontwikkelde technieken die een enorme impact hebben op de mentale en fysieke gezondheid van miljoenen mensen. Koud water en ademhaling, in combinatie met de juiste mindset, versterken de menselijke energie, reduceren stress en pijn en bieden een tegenwicht aan aandoeningen zoals depressies en chronische ontstekingen.',
                    'Wim gaf praktische tips en linkte ze aan wetenschappelijk onderzoek. PAUSE liet hem daarbij letterlijk op het water lopen, vlak bij het Atomium in Brussel. Dit als metafoor voor “de mens kan meer dan men denkt”. Wim Hof, die Di Caprio of Oprah tot zijn aanhangers kan rekenen, ontwikkelde deze technieken, die ook leidden tot 26 wereldrecords.',
                    'PAUSE steunde onafhankelijk wetenschappelijk onderzoek naar de Wim Hof Methode.',
                ],
                video: {
                    file: '834689ba-baea-4460-89d1-0e67e4af888d',
                },
                quote: {
                    text: 'Deze PAUSE talk was een fantastische uitzending. Het ging over het vinden van kracht, geluk en gezondheid door een aantal technieken. Deze kunnen jouw dagelijkse leven veranderen.',
                    author: 'Wim Hof',
                    avatar: 'avatar-tim-hof.webp',
                },
            },
        ],
    },
    about_pause: {
        title: 'Over Pause',
        blocks: [
            'werd opgericht door een groep jonge kunstenaars.',
            'nodigt intellectuele rocksterren uit voor een uitzonderlijke talk.',
            'selecteert een fascinerende locatie die dient als katalysator van het onderwerp.',
            'schenkt een deel van de opbrengsten aan het goede doel of wetenschappelijk onderzoek, aansluitend op het onderwerp.',
            'is volledig onafhankelijk en op geen enkele manier politiek gebonden.',
            ': een moment van rust temidden alle impulsen door.',
        ],
    },
    contact_us: 'Contact',
    faq_title: 'Heb je vragen?',
    check_faq: 'Bekijk onze FAQ',
    enter_the_pause_universe: 'ENTER HET <strong>PAUSE </strong>UNIVERSUM',
    fill_in_code: 'Vul hier uw geheime code in',
    your_code_is_valid: 'Uw toegangscode is geldig',
    code_error:
        'U heeft de verkeerde code ingevuld. Gelieve het opnieuw te proberen.',
    too_early:
        'Dank om je code in te vullen. Momenteel is het nog te vroeg om in te loggen; gelieve terug te komen vanaf 19:00 op 02 Mar 2022.',
    too_early_l1: 'Dank om je code in te vullen. ',
    too_early_l2: 'Momenteel is het nog te vroeg om in te loggen',
    too_early_l3: 'Gelieve terug te komen vanaf 18:00 op 28 December 2023.',
    too_early_l4: 'De uitzending start om 20:00',
    wrong_code: 'U heeft de foute code ingevuld. Probeer opnieuw aub!',
    faq: [
        {
            title: 'Welk goed doel werd er gekozen voor de volgende talk?',
            items: [
                '<p>PAUSE steunt de vzw Te Gek?! voor de talk met Dirk De Wachter.</p>',
                '<p>Bij onze vorige talks hebben wij reeds gesteund:</p>',
                '<ol>',
                '<li>',
                'De officiële Stephen Hawking Foundation, bij de talk door Prof. Thomas Hertog over Tijd.',
                '</li>',
                '<li>',
                'De vzw Te Gek?!, die het taboe rond mentaal welzijn wil doorbreken, bij de talks van Prof. Dirk De Wachter',
                '</li>',
                '<li>',
                'Wetenschappelijk onderzoek aan de Universiteit van Californië, bij de talk van Wim Hof',
                '</li>',
                '</ol>',
            ],
        },
        {
            title: 'Wanneer begint de ticketverkoop?',
            items: [
                `<p>De ticketverkoop start op donderdag 7 november om 10:00. Tickets te bestellen via <strong><anchor href="${linktree}" target="_blank">deze link</anchor></strong>.</p>`,
            ],
        },
        {
            title: 'In welke taal zal de talk uitgezonden worden?',
            items: [
                '<p>De talk is in het Nederlands en zal ondertiteld zijn in het Nederlands.</p>',
            ],
        },
        {
            title: 'Hoelang zal de talk beschikbaar zijn online, na de première?',
            items: [
                '<p>De talk blijft 10 dagen beschikbaar na de uitzending.</p>',
            ],
        },
        {
            title: 'Wat heb ik nodig om de talk te bekijken?',
            items: [
                '<ol>',
                '<li>',
                'Een ticket dat je kunt bestellen via onze ticketpagina. Vanuit de bevestigingsmail met het e-ticket dat je na het afronden van je bestelling ontvangt, kun je doorklikken naar de uitzending.',
                '</li>',
                '<li>',
                '<p>Een smartphone, tablet, computer of smart-tv om de talk op te bekijken. Dit apparaat moet beschikken over een up to date internetbrowser:</p>',
                '<p>Desktop: Apple OSX: Safari 12 en hoger<br />FireFox 71 en hoger<br />Chrome 81 en hoger</p>',
                '<p>Desktop: Windows<br />Edge 80 en hoger<br />FireFox 71 en hoger<br />Chrome 81 en hoger</p>',
                '<p>Mobile/tablet: IOS (12 en hoger)<br />Safari<br />Mobile/tablet: Android (8 en hoger)<br />Chrome</p>',
                '</li>',
                '<li>Een goede internetverbinding, dus snelle WiFi, een 4G verbinding of een vaste kabel.</li>',
                '</ol>',
            ],
        },
        {
            title: 'Kan ik de talk casten naar mijn tv?',
            items: [
                '<p>Je kunt de talk zeker ook via je televisie bekijken. Het casten van de talk kan via verschillende opties.:</p>',
                '<ol>',
                '<li>',
                '<strong>Via Airplay (Apple TV)</strong>',
                '<br />Vanaf een iPhone of iPad',
                '<br />Verbind jouw iPhone of iPad met hetzelfde WiFi-netwerk als jouw Apple TV of AirPlay 2-compatibele smart TV. Ga via je iPhone of iPad naar de talk, tik op het Airplay icoon in de videoplayer en kies het apparaat waarop jij wilt kijken.',
                'Vanaf een Mac',
                '<br />Verbind jouw Mac met hetzelfde WiFi-netwerk als jouw Apple TV of AirPlay 2-compatibele smart TV. Ga op je Mac naar de talk en klik in de videoplayer op het Airplay icoon. Kies het apparaat waarop jij wilt kijken.',
                '</li>',
                '<li>',
                '<strong>Via Chromecast</strong>',
                '<br />Via je Chrome browser (desktop of laptop)',
                '<br />Open Chrome op je computer en ga naar de talk. Klik rechtsboven op Meer en vervolgens op Casten. Kies het Chromecast-apparaat waarop je de talk wil bekijken. Als je de Chromecast al gebruikt, vervangt de content wat er op dat moment wordt weergegeven op de tv.',
                '<br /><br />Jouw computerscherm casten',
                "<br />Je kunt jouw volledige computerscherm weergeven via Chrome op Mac, Windows en Chromebooks. Open hiervoor Chrome op jouw computer en ga naar de talk. Klik rechtsboven op Meer en vervolgens op Casten. Klik bovenaan naast 'Casten naar' op de pijl-omlaag en kies voor Bureaublad casten. Kies het Chromecast-apparaat waarop je de talk wil bekijken.",
                '<br /><br />Jouw volledige Android-scherm casten<br />',
                'Zorg ervoor dat jouw mobiele telefoon of tablet is verbonden met hetzelfde WiFi-netwerk als het Chromecast-apparaat. Open de Google Home-app. Tik op het apparaat waarnaar je het scherm wil casten. Tik op Mijn scherm casten en vervolgens op Scherm casten.',
                '</li>',
                '<li>',
                '<strong>Via een HDMI-kabel</strong><br />',
                'Sluit de HDMI-kabel aan op de uitgang van je computer en op de HDMI-ingang van je TV. Selecteer bij de beeldinstellingen van je besturingssysteem de TV als output. Zet de TV aan, en kies de gebruikte HDMI-ingang van je TV als input. Daarna zal je TV meteen het beeld, én geluid van je computer weergeven. Ga vervolgens via je computer naar de talk.',
                '</li>',
                '<li>',
                '<strong>Via jouw smart-tv</strong><br />',
                'Ga via het menu van je smart-tv naar de applicatie van de internetbrowser. Navigeer via het internet naar <anchor href="https://pause-invites.com">https://pause-invites.com</anchor> om deze pagina op je televisie te openen. Speel de talk af door op de play button te drukken.\n',
                '</li>',
                '</ol>',
            ],
        },
    ],
    jobs: {
        title: 'PAUSE vacature',
        header: {
            blocks: [
                '<strong>PAUSE</strong> is an online platform inviting the most compelling intellectual rock stars of our time for a one off talk in a cinematographic setting. Invitees include ‘the iceman’ Wim Hof, psychiatrist Dirk De Wachter and Stephen Hawking’s closest collaborator.',
                '<strong>IIIMAGINE</strong> organises extraordinary electronic music shows at inspiring locations whereby new music is composed by Curtis Alto for every edition. Previous shows include Castle Gravensteen, the Royal Aviation Hall and Rubens House. We strive that the IIIMAGINE shows will be glued in people’s memories for the next 50 years.',
            ],
        },
        content: {
            title: 'Open Vacancies',
            your_role: 'Your role',
            requirements: 'Requirements',
            vacancies: [
                {
                    title: 'Digital Creative (full-time)',
                    blocks: [
                        'We are looking for an enthusiastic and experienced <strong>digital creative</strong> to join our team. We believe both IIIMAGINE and PAUSE have the potential to change people’s lives for the better and are looking for someone who wants to build with us on this dream. We believe that a very strong team can accomplish a great deal. ',
                    ],
                    role: [
                        'IIIMAGINE and PAUSE are about artistic, cool and meaningful stories. You will bring them online. Join our thinking process about new digital ideas, develop storylines into content and contribute to the growth of our digital platforms. We will coach you to be immersed into the vision of both IIIMAGINE and PAUSE and give you a lot of freedom.',
                        'Be involved with our collaborations: with visual artists, musicians, light and stage designers, painters, fashion designers, etc',
                        'Create graphic designs for social media posts. We are not talking about cheap giveaway actions but about creating thoughtful content.',
                        'Edit videos for social media posts.',
                        'You have an eye for aesthetics and could envisage directing that vision to the photographers, videographers and other artists during the shows and filming days.',
                    ],
                    requirements: [
                        'You are interested in art, new technologies and music and have a broad interest in general',
                        'You have a first experience, preferably in a dynamic, creative and tech-driven environment ',
                        'You have a strong interest in meaningful online and offline content & stories',
                        'You have a knowledge of Premiere, Photoshop, After Effects and are eager to learn a lot',
                        'You are fluent in Dutch and English (written/spoken) and have a skill to put feelings into words',
                        'You want to work in a startup environment and take ownership of what you do ',
                        'You can come to our office in Ghent',
                        'We are looking for someone who is good, in addition to being excellent at what she/he does!',
                    ],
                    video: 'III_DC_VACATURE_v02.mp4',
                    end_blocks: [
                        `Interested? Send us an email with your cover letter + CV at <br/><anchor href="${mailInfo}">info@pause-invites.com</anchor>`,
                    ],
                },
            ],
        },
    },

    enter_name_for_chat:
        'PAUSE heeft een levendige chat. Voer jouw naam in en neem deel.',
    type_name: 'Je naam',
    type_message: 'Type je bericht...',
    chat_closed: 'Chat is gesloten...',
    days: 'Dagen',
    hours: 'Uren',
    minutes: 'Minuten',
    seconds: 'Seconden',
    continue: 'Doorgaan',
    questions_take_a_look: `Vragen? Bekijk onze <2 to="/faq">FAQ</2> of <5>contacteer ons</5>`,
};
